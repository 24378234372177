import PrimaryButton from '@components/buttons/primary/PrimaryButton';
import { useAuthState } from '@src/providers/AuthProvider';
import { useModal } from '@src/providers/ModalProvider';
import { AuthScreenType } from '@src/utils/enums';

import dynamic from 'next/dynamic';
const Authentication = dynamic(() => import('@components/modal/authentication/Authentication'));

export default function CreateBriefView() {
  const { isAuthenticated } = useAuthState();
  const { setModalContent } = useModal();

  return (
    <div className="flex flex-col w-full justify-center items-center px-4">
      <div
        className="w-full max-w-[632px] h-full p-4 rounded-xl border border-greyscale-g3
              md:flex space-y-3 md:space-y-0 space-x-0 md:space-x-6"
      >
        <div className="flex flex-col justify-center items-start flex-grow">
          <span className="text-m text-content-t100 font-semibold">
            Muốn hoạ sĩ ứng commission?
          </span>
          <span className="text-s text-content-t100">
            Chỉ cần đăng brief, Dreamerly sẽ tìm hoạ sĩ giùm bạn nhé.
          </span>
        </div>
        <div className="w-full md:w-28 flex justify-center items-center">
          {!isAuthenticated && (
            <PrimaryButton
              sizeType="md"
              isFullWidth
              onClick={() => {
                setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
              }}
            >
              <span className="text-s-medium text-white font-semibold">Đăng brief</span>
            </PrimaryButton>
          )}
          {isAuthenticated && (
            <PrimaryButton sizeType="md" isLink href="/jobs/create" isFullWidth>
              <span className="text-s-medium text-white font-semibold">Đăng brief</span>
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
}
