import TextField from '@mui/material/TextField';

export type MultiLineInputFieldProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  sizeType: 'sm' | 'md';
  errorMessage?: string;
  disabled?: boolean;
  rows?: number;
  maxRows?: number;
  minRows?: number;
  autoFocus?: boolean;
};

export default function MultiLineInputField(props: MultiLineInputFieldProps) {
  const {
    placeholder,
    value,
    onChange,
    sizeType,
    errorMessage,
    disabled,
    rows,
    minRows,
    maxRows,
    autoFocus,
  } = props;

  const smInputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-2 px-3 w-full
    placeholder:text-content-t300 outline-none`;
  const smErrorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-2 px-3 w-full
    placeholder:text-content-t300 outline-none`;
  const mdInputClassName = `border border-1 border-greyscale-g3
    hover:shadow-input-focus focus:shadow-input-focus
    rounded-lg py-4 px-4 w-full
    placeholder:text-content-t300 outline-none`;
  const mdErrorInputClassName = `border border-1 border-status-error
    hover:shadow-input-focus-error focus:shadow-input-focus-error
    rounded-lg py-4 px-4 w-full
    placeholder:text-content-t300 outline-none`;

  const getInputClassName = () => {
    switch (sizeType) {
      case 'sm':
        if (errorMessage) {
          return smErrorInputClassName;
        }
        return smInputClassName;
      case 'md':
        if (errorMessage) {
          return mdErrorInputClassName;
        }
        return mdInputClassName;
    }
  };

  return (
    <div className="w-full">
      <div className={getInputClassName()}>
        <TextField
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              padding: '0px',
            },
            '.MuiInputBase-input': {
              fontFamily: 'Sarabun',
              fontSize: '16px',
              color: '#303030',
              '&::placeholder': {
                color: '#717171',
                opacity: 1,
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'hidden',
              },
            },
          }}
          multiline
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      </div>
      {errorMessage && <span className="text-s text-status-error pt-2">{errorMessage}</span>}
    </div>
  );
}
