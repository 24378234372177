import Footer from '@components/bottom/Footer';
import Header from '@components/header/Header';
import Meta from '@components/meta/Meta';
import HomePageProductCard from '@components/product/HomePageProductCard';
import { Product } from '@src/API';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import {
  LG_SCREEN_IN_PX,
  MD_SCREEN_IN_PX,
  NONE_STRING_VALUE,
  SM_SCREEN_IN_PX,
  XL_SCREEN_IN_PX,
} from '@src/utils/constants';
import { MixpanelTracking } from '@src/utils/mixpanelService';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import 'photoswipe/dist/photoswipe.css';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Lazy Load
import { useListProductsRandomizedQuery } from '@src/services/productHooks';
import { IProductService, RealProductService } from '@src/services/productService';
import { ALL_CATEGORY_ID } from '@src/utils/enums';

import NewCharacterDrawerOrDialogView from '@components/character/NewCharacterDrawerOrDialogView';
import CreateBriefView from '@components/home/CreateBriefView';
import ShowMoreDrawerOrDialog from '@components/showmore/ShowMoreDrawerOrDialog';
import { useAuthState } from '@src/providers/AuthProvider';
import dynamic from 'next/dynamic';
const HomePageFilterBar = dynamic(() => import('@components/product/HomePageFilterBar'), {
  ssr: true,
});
const CircularProgress = dynamic(() => import('@mui/material/CircularProgress'));
const BottomAppBar = dynamic(() => import('@components/bottom/BottomAppBar'), { ssr: true });

const MetaDescription = `Dreamerly là nền tảng mua bán commission minh bạch,
  an toàn cho khách hàng và hoạ sĩ Việt: Chibi, Anime, Vtuber, YCH, 3D, và hơn
  thế nữa.`;

// Home
type HomeProps = {
  initialProducts: Product[];
  sessionId: string;
};

const DEFAULT_DREAMERLY_SESSION_ID = 'dreamerly-session-id';

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const productService: IProductService = new RealProductService();

  const data = await productService.getListProductRandomize({
    sessionId: process.env.NEXT_PUBLIC_DREAMERLY_SESSION_ID || DEFAULT_DREAMERLY_SESSION_ID,
    filterIsSelling: 'all',
    filterMinPrice: 0,
    filterMaxPrice: 100000000,
    limit: 12,
    categoryId: ALL_CATEGORY_ID,
    isFixedSessionResult: 'false',
    page: 0,
  });
  const initialProducts = (data?.list || []) as Product[];
  const sessionId = uuidv4();

  return {
    props: {
      initialProducts,
      sessionId,
    },
    revalidate: 600, // 10 minutes
  };
};

function Home(props: InferGetStaticPropsType<typeof getStaticProps>) {
  const { initialProducts, sessionId } = props;
  console.log('QQ: initialProducts', initialProducts);

  const bottomRef = useRef<any>(null);

  // Update numColumns depending on the width of the screen.
  const { width: windowInnerWidth } = useWindowDimensions();
  const router = useRouter();
  const fullPathUrl = new URL(router.asPath, process.env.NEXT_PUBLIC_DOMAIN || '').href;
  const { query } = router;

  /* States */
  const [products, setProducts] = useState<Product[]>(initialProducts); // The actual list of products to show
  const [rawProducts, setRawProducts] = useState<Product[]>(initialProducts); // The actual list of products to show
  const [sessionIdWithCategoryId, setSessionIdWithCategoryId] = useState<string>(
    `${sessionId}-${NONE_STRING_VALUE}_${NONE_STRING_VALUE}`,
  );
  // state for filter
  const [filterCategoryId, setFilterCategoryId] = useState<string>(NONE_STRING_VALUE);
  const [filterMinPrice, setFilterMinPrice] = useState<number>(0);
  const [filterMaxPrice, setFilterMaxPrice] = useState<number>(100000000);
  const [filterIsSelling, setFilterIsSelling] = useState<string>('all'); // Value is 'all', 'open'
  const [filterRating, setFilterRating] = useState<number>(0); // if 0, show all products
  const [isMobile, setIsMobile] = useState(true);
  const [nextPage, setNextPage] = useState<number | undefined | null>(0);
  const [activeFilterCounts, setActiveFilterCounts] = useState<number>(0);
  const [queryParamsLoaded, setQueryParamsLoaded] = useState<boolean>(false);
  const [limitItemPerPage, setLimitItemPerPage] = useState<number>(10);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  // Whenever we change category or apply new filter, router.replace will trigger this const to regenerate a new key
  // This will force the react query to refetch data because we include this as a query key there
  const [categorySessionKey, setCategorySessionKey] = useState<string>(uuidv4());

  // State for create character drawer or dialog
  const [isDrawerOrDialogOpen, setIsDrawerOrDialogOpen] = useState(false);

  // Authentication state
  const { user: authUser } = useAuthState();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsScrollBottom(entry.isIntersecting));

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [bottomRef]);

  // Infinite scroll
  const {
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: listProductsRandomized,
  } = useListProductsRandomizedQuery(
    sessionIdWithCategoryId,
    categorySessionKey,
    filterIsSelling,
    filterMinPrice,
    filterMaxPrice,
    limitItemPerPage,
    nextPage,
    initialProducts,
  );

  /* Effects */
  useEffect(() => {
    console.log('QQ: listProductsRandomized unique', listProductsRandomized);

    /*
      Structure of listProductsRandomized:
      [
        { list: Product[] },
        { list: Product[] }
      ]
    */

    const lastPage = listProductsRandomized?.pages[listProductsRandomized?.pages.length - 1];
    if (lastPage?.nextToken) {
      setNextPage(parseInt(lastPage?.nextToken || '') || null);
    }

    const updatedData: Product[] = [];
    listProductsRandomized?.pages.forEach((page) => {
      const items = page?.list ?? [];
      items.forEach((item) => {
        const existedItem = updatedData.find((i) => i.id === item.id);
        if (!existedItem) {
          updatedData.push(item);
        }
      });
    });

    setRawProducts(updatedData);
  }, [listProductsRandomized]);

  useEffect(() => {
    if (!queryParamsLoaded && router.isReady && query) {
      const queryCategoryId = query.category_id as string;
      const queryMinPrice = parseInt((query.min_price as string) || '0');
      const queryMaxPrice = parseInt((query.max_price as string) || '0');
      const queryIsSelling = query.is_selling as string;
      const queryRating = parseInt((query.rating as string) || '0');
      setFilterCategoryId(queryCategoryId || ALL_CATEGORY_ID);
      setFilterMinPrice(queryMinPrice || 0);
      setFilterMaxPrice(queryMaxPrice || 100000000);
      setFilterIsSelling(queryIsSelling || 'all');
      setFilterRating(queryRating || 0);
      if (queryCategoryId !== ALL_CATEGORY_ID) {
        setNextPage(0);
      } else {
        setNextPage(1);
      }
      setQueryParamsLoaded(true);
    }
  }, [router.isReady, query, queryParamsLoaded]);

  useEffect(() => {
    setSessionIdWithCategoryId(`${sessionId}-${filterCategoryId}_${filterCategoryId}`);
    if (filterCategoryId !== ALL_CATEGORY_ID) {
      setNextPage(0);
    } else {
      setNextPage(1);
      setIsScrollBottom(true);
    }
  }, [filterCategoryId, sessionId]);

  useEffect(() => {
    if (!rawProducts || !queryParamsLoaded) return;
    console.log(
      'thanh 0826 all filter ',
      filterCategoryId,
      filterMinPrice,
      filterMaxPrice,
      filterIsSelling,
      filterRating,
    );
    let filteredProducts = rawProducts;
    console.log('QQ: filteredProducts0', filteredProducts);

    // filter out product without basic info
    filteredProducts = filteredProducts.filter(
      (product) =>
        ((product.product_artworks?.items?.length || 0) > 0 ||
          (product.artist?.artworks?.items.length || 0) > 0) &&
        product.min_price_amount &&
        product.max_price_amount &&
        product.display_name &&
        product.artist?.display_name,
    );
    console.log('QQ: filteredProducts1', filteredProducts);
    // filter by category happend in the react query so don't handle here
    // filter by price
    filteredProducts = filteredProducts.filter(
      (product) =>
        parseInt(product.min_price_amount || '0') <= filterMaxPrice &&
        parseInt(product.max_price_amount || '0') >= filterMinPrice,
    );
    console.log('QQ: filteredProducts2', filteredProducts);
    // filter by isSelling
    if (filterIsSelling === 'open') {
      filteredProducts = filteredProducts.filter((product) => product.artist?.is_selling === 1);
    } else if (filterIsSelling === 'close') {
      filteredProducts = filteredProducts.filter((product) => product.artist?.is_selling === 0);
    }
    console.log('QQ: filteredProducts3', filteredProducts);
    // filter by rating
    if (filterRating >= 0) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          (product.product_star_quantity || 0) / (product.product_review_quantity || 1) >=
          filterRating,
      );
    }
    console.log('QQ: filteredProducts4', filteredProducts);
    setProducts(filteredProducts);
    // Set active filter counts
    let filterCounts = 0;
    if (filterMinPrice !== 0 || filterMaxPrice !== 100000000) filterCounts++;
    if (filterIsSelling !== 'all') filterCounts++;
    if (filterRating > 0) filterCounts++;
    setActiveFilterCounts(filterCounts);
  }, [
    rawProducts,
    filterMinPrice,
    filterMaxPrice,
    filterIsSelling,
    filterRating,
    filterCategoryId,
    queryParamsLoaded,
  ]);

  const applyFilterToRouter = ({
    filterMinPrice,
    filterMaxPrice,
    filterIsSelling,
    filterRating,
    filterCategoryId,
  }: {
    filterMinPrice: number;
    filterMaxPrice: number;
    filterIsSelling: string;
    filterRating: number;
    filterCategoryId: string;
  }) => {
    if (!queryParamsLoaded) return;
    // check if any filter is applied
    if (
      filterMinPrice !== 0 ||
      filterMaxPrice !== 100000000 ||
      filterIsSelling !== 'all' ||
      filterRating !== 0
    ) {
      // eslint-disable-next-line max-len
      const queryString = `?min_price=${filterMinPrice}&max_price=${filterMaxPrice}&is_selling=${filterIsSelling}&rating=${filterRating}&category_id=${filterCategoryId}`;
      router.replace(`/${queryString}`, undefined, {
        scroll: false,
        shallow: true,
      });
    } else {
      if (filterCategoryId !== ALL_CATEGORY_ID) {
        const queryString = `?category_id=${filterCategoryId}`;
        router.replace(`/${queryString}`, undefined, {
          scroll: false,
          shallow: true,
        });
      } else {
        router.replace(`/`, undefined, {
          scroll: false,
          shallow: true,
        });
      }
    }
  };

  // Track page view
  useEffect(() => {
    MixpanelTracking.getInstance().trackHomePageView(new Date().toISOString());
  }, []);

  useEffect(() => {
    if (!windowInnerWidth) return;

    // Correspond to config breakpoints
    if (windowInnerWidth < SM_SCREEN_IN_PX) {
      setIsMobile(true);
      setLimitItemPerPage(10);
    } else if (windowInnerWidth < MD_SCREEN_IN_PX) {
      setIsMobile(true);
      setLimitItemPerPage(10);
    } else if (windowInnerWidth < LG_SCREEN_IN_PX) {
      setIsMobile(false);
      setLimitItemPerPage(15);
    } else if (windowInnerWidth < XL_SCREEN_IN_PX) {
      setIsMobile(false);
      setLimitItemPerPage(15);
    } else {
      setIsMobile(false);
      setLimitItemPerPage(15);
    }
  }, [windowInnerWidth]);

  useEffect(() => {
    if (isScrollBottom) {
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage();
      setIsScrollBottom(false);
    }
  }, [isScrollBottom, isFetchingNextPage, hasNextPage, fetchNextPage]);

  // Functions
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // Open/close drawer or dialog to create new character
  const handleDrawerOrDialogClose = () => {
    setIsDrawerOrDialogOpen(false);
  };

  const handleDrawerOrDialogOpen = () => {
    setIsDrawerOrDialogOpen(true);
  };

  /* Render Views */
  console.log(products, 'productsproductsproducts');
  return (
    <div>
      <Meta
        title="Nền tảng mua bán commission của Việt Nam | Dreamerly"
        url={fullPathUrl}
        description={MetaDescription}
      />
      <div className="flex flex-col items-center">
        <Header hasShadow={false} />

        <HomePageFilterBar
          selectedCategoryId={filterCategoryId}
          setSelectedCategoryId={setFilterCategoryId}
          selectedIsSelling={filterIsSelling}
          setSelectedIsSelling={setFilterIsSelling}
          selectedMinPrice={filterMinPrice}
          setSelectedMinPrice={setFilterMinPrice}
          selectedMaxPrice={filterMaxPrice}
          setSelectedMaxPrice={setFilterMaxPrice}
          selectedRating={filterRating}
          setSelectedRating={setFilterRating}
          activeFilterCounts={activeFilterCounts}
          handleScrollToTop={handleScrollToTop}
          isMobile={isMobile}
          applyFilter={applyFilterToRouter}
        />

        {/* Create brief section  */}
        <div className="pt-4">
          <CreateBriefView />
        </div>

        <main className="max-w-screen-2xl w-full min-h-screen flex flex-col my-6 md:px-6 lg:px-12 pb-12">
          {/* Product list section  */}
          <div className="grid grid-cols-1 gap-6">
            {products.map((product, index) => (
              <HomePageProductCard
                key={product.id}
                product={product}
                isMobile={isMobile}
                isLastItem={index === products.length - 1}
                cardIndex={index}
              />
            ))}
          </div>

          <div ref={bottomRef}></div>

          {hasNextPage && isFetchingNextPage && (
            <div className="flex items-center justify-center py-2 my-3 z-0 w-full bg-transparent">
              <CircularProgress
                color="primary"
                size={30}
                sx={{
                  color: '#1976D2',
                }}
              />
            </div>
          )}
        </main>

        <Footer isStickyFooterEnabled />
        {/* Add bottom padding to account for BottomAppBar */}
        <BottomAppBar />
      </div>
      {!!authUser && (
        <ShowMoreDrawerOrDialog
          type={isMobile ? 'drawer' : 'dialog'}
          open={isDrawerOrDialogOpen}
          onClose={handleDrawerOrDialogClose}
          title="Tạo nhân vật mới"
        >
          <NewCharacterDrawerOrDialogView authUser={authUser} />
        </ShowMoreDrawerOrDialog>
      )}
    </div>
  );
}

export default Home;
