import DrDivider from '@components/DrDivider';
import SecondaryButton from '@components/buttons/secondary/SecondaryButton';
import MainAvatar from '@components/profile/MainAvatar';
import { Artwork, Product } from '@src/API';
import * as userExt from '@src/models/extensions/user+ext';
import { MD_SCREEN_IN_PX, SM_SCREEN_IN_PX, category_id_map } from '@src/utils/constants';
import { CurrencyCode } from '@src/utils/enums';
import { formatCurrency } from '@src/utils/formatText';
import { customImageLoader, getImageSrcUrl, getThumbnailUrl } from '@src/utils/media';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';
import Image from 'next/image';
import Link from 'next/link';
import 'photoswipe/dist/photoswipe.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { v4 as uuidv4 } from 'uuid';

function HomePageProductCard({
  product,
  isMobile,
  isLastItem,
  cardIndex,
}: {
  product: Product;
  isMobile?: boolean;
  isLastItem?: boolean;
  cardIndex: number;
}) {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  // Get artworks to show
  const [artworks, productArtworksLength] = useMemo(() => {
    let results: (Artwork | null | undefined)[] = [];
    // First, get the artworks from the product
    const artworksFromProduct = (product.product_artworks?.items || [])
      .filter((item) => !!item?.artwork)
      .map((item) => item?.artwork) as Artwork[];
    let productArtworksLength = 5;
    // If artworksFromProduct.length = 0, get the artworks from the artist. If
    // artworksFromProduct.length < 0, duplicate the artworks from the product,
    // then slice to get 5 artworks.
    if (artworksFromProduct.length === 0) {
      // No artworks from product, get artworks from artist
      results = (product.artist?.artworks?.items || []).slice(0, 5);
      productArtworksLength = 0;
    } else if (artworksFromProduct.length < 5) {
      // Artworks from product < 5, duplicate artworks from product, but change
      // artworkId
      const duplicatedArtworksFromProduct = artworksFromProduct.map((artwork) => ({
        ...artwork,
        id: uuidv4(),
      }));
      results = [...artworksFromProduct, ...duplicatedArtworksFromProduct].slice(0, 5);
      productArtworksLength = results.length;
    } else {
      results = artworksFromProduct;
      productArtworksLength = results.length;
    }

    return [results, productArtworksLength];
  }, [product]);

  // Get seller ratings
  let sellerRating = 'Chưa có đánh giá';
  if (product?.artist) {
    const rating = userExt.getAvgRating(product.artist);
    if (rating !== null) {
      sellerRating = `${rating} · ${product.artist.seller_review_quantity} đánh giá`;
    }
  }

  const isProductActive = product.status === 'ACTIVE' && product.artist?.is_selling === 1;

  // Enable click to scroll mechanism.
  // Ref: https://www.embla-carousel.com/guides/previous-and-next-buttons/
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    containScroll: 'trimSnaps',
  });

  /**
   * Scroll next page: Scroll to the last index of slidesInView.
   */
  const scrollNextPageEmbla = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(emblaApi.slidesInView().slice(-1)[0]);
  }, [emblaApi]);

  /**
   * Scroll previous page: Scroll to index 0 of slidesInView.
   */
  const scrollPrevPageEmbla = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(emblaApi.slidesInView()[0]);
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);

    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="relative flex flex-col w-full">
      <Link href={`/products/${product.slug}`} passHref>
        <a
          className="absolute flex flex-col shrink-0 h-full w-full"
          target={!isMobile ? '_blank' : undefined}
          rel={!isMobile ? 'noopener noreferrer' : undefined}
        />
      </Link>
      <div className="flex  flex-col-reverse lg:flex-row lg:justify-between w-full pb-6">
        {/* Product information segment */}
        <div
          className="w-full lg:w-80 flex flex-col items-start justify-between
          px-4 md:px-0 pt-4 lg:pt-0 lg:mr-6 shrink-0"
        >
          <div className="w-full flex items-start pb-4">
            <div className="w-full flex flex-col items-start space-y-1">
              <span className="text-m line-clamp-2 font-semibold">
                {product.display_name || ''} · {product.artist?.display_name}
              </span>
              <div className="flex items-center space-x-1 md:space-x-2">
                <div className="relative shrink-0 w-4 h-4">
                  <Image
                    src="/ic-profile-star.svg"
                    layout="fill"
                    objectFit="contain"
                    alt="Star icon"
                    sizes="20px"
                  />
                </div>
                <span className="text-content-t200">{sellerRating}</span>
              </div>
              {product.min_price_amount === product.max_price_amount && (
                <div className="text-m font-semibold">
                  {product.category_id === category_id_map.Dreambox ? '' : 'Từ '}
                  {formatCurrency(
                    product.min_price_amount,
                    product.currency_code || CurrencyCode.VND,
                  )}
                </div>
              )}
              {product.min_price_amount !== product.max_price_amount && (
                <div className="text-m font-semibold">
                  {formatCurrency(
                    product.min_price_amount,
                    product.currency_code || CurrencyCode.VND,
                  )}{' '}
                  -{' '}
                  {formatCurrency(
                    product.max_price_amount,
                    product.currency_code || CurrencyCode.VND,
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row items-center pl-2 shrink-0">
              <MainAvatar
                sizeType={isMobile ? 'xs-tall' : 'sm'}
                imageSrc={product.artist?.profile_picture_url || ''}
                linkUrl={`/users/${product.artist?.username || ''}`}
                displayName={product.artist?.display_name || 'Hoạ sĩ'}
                canOpenProfile={true}
                isOpenNewTab={isMobile ? false : true}
              />
            </div>
          </div>

          {isProductActive && (
            <SecondaryButton sizeType="md" isFullWidth disabled>
              {product.category_id === category_id_map.Dreambox ? (
                <div className="flex items-center space-x-2">
                  <Image src="/ic-dreambox-blue.svg" width={20} height={20} alt="Purchase icon" />
                  <span className="text-primary-p4 font-semibold">Đặt Dreambox</span>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <Image
                    src="/ic-purchase-primary.svg"
                    width={20}
                    height={20}
                    alt="Purchase icon"
                  />
                  <span className="text-primary-p4 font-semibold">Đặt commission</span>
                </div>
              )}
            </SecondaryButton>
          )}
          {!isProductActive && (
            <button
              className="flex flex-row items-center justify-center
            font-semibold text-content-t100 rounded-md outline-greyscale-g3 px-4 py-2 text-m outline outline-1
            w-full bg-greyscale-g1"
              disabled
            >
              <div className="flex items-center space-x-2">
                <Image src="/ic-purchase-off.svg" width={20} height={20} alt="Purchase icon" />
                <span className="text-content-t100 font-semibold">Tạm đóng</span>
              </div>
            </button>
          )}
        </div>
        {/* Artwork segment */}
        {artworks.length !== 0 && (
          <div className="overflow-hidden w-full embla">
            <div className="relative embla__viewport" ref={emblaRef}>
              <div
                className="flex space-x-2 md:space-x-3 embla__container pl-4 md:pl-0 mr-4 md:mr-0
                h-40 md:h-56 lg:h-60"
              >
                <Gallery>
                  {artworks.map((artwork, index) => (
                    <Item
                      key={artwork?.id || uuidv4()}
                      original={getImageSrcUrl(artwork?.cover_image_src_url)}
                      thumbnail={getThumbnailUrl(artwork?.cover_image_thumbnail_url)}
                      width={artwork?.cover_image_width || 0}
                      height={artwork?.cover_image_height || 0}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref as React.MutableRefObject<HTMLImageElement>}
                          className="embla__slide flex relative h-40 w-40 md:h-56 md:w-56 lg:h-60 lg:w-60
                          rounded-lg overflow-hidden shrink-0 hover:brightness-90"
                          role="button"
                          onClick={open}
                        >
                          <Image
                            src={
                              artwork?.cover_image_thumbnail_url ||
                              'https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg'
                            }
                            loader={customImageLoader}
                            layout="fill"
                            objectFit="cover"
                            alt="Artist photo"
                            loading={cardIndex < 6 ? 'eager' : 'lazy'}
                            priority={cardIndex < 6 ? true : false}
                            sizes={`(max-width: ${SM_SCREEN_IN_PX}px) 160px,
                                (max-width: ${MD_SCREEN_IN_PX}px) 224px,
                                240px`}
                          />
                          {index >= productArtworksLength && (
                            <div
                              className="flex items-center rounded-md absolute bottom-2 left-2
                            px-2 py-1 bg-black/50"
                            >
                              <span className="text-xs text-white">Từ Portfolio</span>
                            </div>
                          )}
                        </div>
                      )}
                    </Item>
                  ))}
                </Gallery>

                {product.category_id === category_id_map.Dreambox && (
                  <div
                    className="flex items-center rounded-md absolute bottom-2 left-4 md:left-0 
                  px-3 py-1 bg-black/50"
                  >
                    <div className="w-4 h-4 md:w-5 md:h-5 relative ">
                      <Image src={'/ic-dreambox-white.svg'} alt={' Dreambox icon'} layout="fill" />
                    </div>
                    <span className="text-xs md:text-sm text-white ml-1">Dreambox</span>
                  </div>
                )}
              </div>
              {/* Only show the arrow button on desktop */}
              <button
                className={
                  prevBtnDisabled
                    ? `hidden md:flex opacity-0 absolute left-4 top-24 z-10 bg-white/90
  rounded-full p-2 border-greyscale-g5 border hover:shadow-lg hover:bg-white`
                    : `hidden md:flex absolute left-4 top-24 z-10 bg-white/90
  rounded-full p-2 border-greyscale-g5 border hover:shadow-lg hover:bg-white`
                }
                onClick={scrollPrevPageEmbla}
              >
                <Image
                  src="/ic-chevron-left.svg"
                  width={16}
                  height={16}
                  alt="Slide to previous page"
                />
              </button>
              <button
                className={
                  nextBtnDisabled
                    ? `hidden md:flex opacity-0 absolute right-4 top-24 z-10 bg-white/90
  rounded-full p-2 border-greyscale-g5 border hover:shadow-lg hover:bg-white`
                    : `hidden md:flex absolute right-4 top-24 z-10 bg-white/90
  rounded-full p-2 border-greyscale-g5 border hover:shadow-lg hover:bg-white`
                }
                onClick={scrollNextPageEmbla}
              >
                <Image
                  src="/ic-chevron-right.svg"
                  width={16}
                  height={16}
                  alt="Slide to next page"
                />
              </button>
            </div>
          </div>
        )}
      </div>
      {!isLastItem && (
        <div className="hidden md:flex">
          <DrDivider />
        </div>
      )}
      {!isLastItem && <div className="flex md:hidden h-1.5 bg-gray-100" />}
    </div>
  );
}

export default HomePageProductCard;
