import Link from 'next/link';
import { MouseEvent, ReactNode } from 'react';

export type SecondaryButtonInputType = {
  children: ReactNode;
  isLink?: boolean;
  isOpenNewTab?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  href?: string;
  sizeType: 'xs' | 'sm' | 'md' | 'lg';
  isAlertStyle?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

/**
 * Secondary button component.
 *
 * There are 3 size types: xs, sm, md. If you want to make the button full
 * width, set isFullWidth to true.
 */
export default function SecondaryButton({
  children,
  isLink,
  isOpenNewTab = false,
  onClick,
  href,
  sizeType,
  isAlertStyle = false,
  isFullWidth = false,
  disabled = false,
  isLoading = false,
  ...props
}: SecondaryButtonInputType & React.HTMLAttributes<HTMLButtonElement>) {
  const baseClassString = `flex flex-row items-center justify-center
    font-semibold text-content-t100 rounded-md outline-greyscale-g3`;
  const baseAlertClassString = `flex flex-row items-center justify-center
    font-semibold text-status-error rounded-md outline-status-error`;
  const baseSpinnerString =
    'animate-spin rounded-full border-2 border-greyscale-g5 border-r-transparent absolute';

  const getCustomClassString = () => {
    switch (sizeType) {
      case 'xs':
        return `px-3 py-1.5 text-s outline outline-1`;
      case 'sm':
        return `px-3 py-1.5 text-m outline outline-1`;
      case 'md':
        return `px-4 py-2 text-m outline outline-1`;
      case 'lg':
        return `px-4 py-3 text-m outline outline-1`;
    }
  };

  const getCustomSpinnerString = () => {
    switch (sizeType) {
      case 'xs':
        return 'h-4 w-4';
      case 'sm':
        return 'h-5 w-5';
      case 'md':
        return 'h-6 w-6';
      case 'lg':
        return 'h-6 w-6';
    }
  };

  let className = `${
    isAlertStyle ? baseAlertClassString : baseClassString
  } ${getCustomClassString()}
    hover:shadow-md hover:z-10 disabled:border-greyscale-g1 disabled:text-greyscale-g1`;
  const spinnerClassName = `${baseSpinnerString} ${getCustomSpinnerString()}`;

  if (isLoading) {
    className = `${className} disabled:border-greyscale-g3`;
  }

  if (isFullWidth) {
    className = `${className} w-full`;
  }

  if (isLink && href && isOpenNewTab) {
    return (
      <Link href={href} passHref>
        <a
          href="replace"
          target="_blank"
          rel="noopener noreferrer"
          className={`${className} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
        >
          {children}
        </a>
      </Link>
    );
  }

  if (isLink && href && !isOpenNewTab) {
    return (
      <Link href={href} passHref>
        <a
          href="replace"
          className={`${className} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      {...props}
      className={`${className} ${props.className ? props.className : ''}`}
    >
      {/* If is loading, keep the children class invisible to keep the same button size */}
      <div className={isLoading ? 'invisible' : ''}>{children}</div>
      {/* Loading animation */}
      {isLoading && <div className={spinnerClassName} />}
    </button>
  );
}
