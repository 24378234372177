export const customGetMilestone = /* GraphQL */ `
  query GetMilestone($id: ID!) {
    getMilestone(id: $id) {
      id
      contract_id
      step_index
      step_name
      deadline_number
      deadline_type
      start_date_time
      end_date_time
      delivery_date_time
      free_revision_count
      current_revision_index
      current_revision_id
      description
      deadline_extensions
      price_percentage
      amount
      centi_amount
      currency_code
      status
      revisions {
        items {
          id
          index
          status
          request_message
          request_rejection_message
          milestone_id
          additional_deadline_number
          additional_deadline_type
          start_date_time
          end_date_time
          delivery_date_time
          deadline_extensions
          images {
            nextToken
            items {
              id
              height
              width
              src_url
              thumbnail_url
              file_name
              artwork_id
              job_id
              milestone_id
              revision_id
              message_id
              created_at
              updated_at
            }
          }
          created_at
          updated_at
        }
        nextToken
      }
      images {
        items {
          id
          height
          width
          file_name
          src_url
          thumbnail_url
          raw_src_url
          raw_thumbnail_url
          artwork_id
          job_id
          milestone_id
          revision_id
          revision_request_revision_id
          message_id
          created_at
          updated_at
        }
        nextToken
      }
      created_at
      updated_at
    }
  }
`;
