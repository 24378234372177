import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import Image from 'next/image';
import { ReactNode } from 'react';

export default function ShowMoreDrawerOrDialog({
  type,
  header,
  children,
  open,
  onClose,
  title,
  maxWidth = 'sm',
  drawerHeight = '100%',
}: {
  type: 'drawer' | 'dialog';
  header?: ReactNode;
  children: ReactNode;
  open: boolean;
  onClose?: (() => void) | undefined;
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md';
  drawerHeight?: string;
}) {
  if (type === 'drawer') {
    return (
      <Drawer
        variant="temporary"
        anchor="bottom"
        open={open}
        onClose={onClose}
        ModalProps={{
          // Better open performance on mobile. TODO (long): Verify this
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', height: drawerHeight || '100%' },
        }}
      >
        <div className="flex flex-col overflow-clip h-full">
          {/* Custom drawer header */}
          {header}
          {/* Default drawer header with back button */}
          {!header && (
            <div className="flex justify-between items-center px-1 py-1">
              <button onClick={onClose} className="flex shrink-0 p-3">
                <Image src="/ic-arrow-left.svg" height={20} width={20} alt="Back button" />
              </button>
              {title && <h1 className="font-semibold">{title}</h1>}
              <div className="w-5 h-5 m-3" />
            </div>
          )}
          {/* Body */}
          {children}
        </div>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth || 'sm'}
      keepMounted={true} // Good for SEO. TODO (long): Verify this
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
        },
      }}
    >
      <div className="flex flex-col overflow-clip">
        {/* Custom dialog header */}
        {header}
        {/* Default dialog header with back button */}
        {!header && (
          <div className="bg-white flex justify-between items-center px-4 py-3">
            <button
              onClick={onClose}
              className="flex shrink-0 p-2 rounded-full hover:bg-greyscale-g1"
            >
              <Image src="/ic-close.svg" height={20} width={20} alt="Close button" />
            </button>
            {title && <h1 className="text-s-tall font-semibold">{title}</h1>}
            <div className="w-5 h-5 m-2" />
          </div>
        )}
        {/* Body */}
        {children}
      </div>
    </Dialog>
  );
}
