import { Contract, Job } from '@src/API';
import mixpanel from 'mixpanel-browser';
import {
  MixpanelCharacterEventProperty,
  MixpanelCreateContractEventProperty,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelSearchEventProperty,
  MixpanelUserProfile,
} from './mixpanelModel';

export class MixpanelTracking {
  private static instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (!MixpanelTracking.instance) {
      MixpanelTracking.instance = new MixpanelTracking();
    }
    return this.instance;
  }

  public constructor() {
    if (MixpanelTracking.instance) {
      throw new Error('Error: Instance creation of MixpanelTracking is not allowed.');
    }
    // Base on this question it's fine to make this public: https://community.mixpanel.com/sending-data-to-mixpanel-11/best-practice-for-protecting-mixpanel-token-in-web-4164
    // In the future we can improve security by implement tracking server side
    const mixpanelTokenId = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_ID;

    const environment = process.env.NEXT_PUBLIC_ENV_NAME || 'local';

    mixpanel.init(mixpanelTokenId || '', {
      debug: environment === 'production' ? false : true, // Disable in production environment
      ignore_dnt: true,
      // Turn on track_pageview by default
      // TODO (long): Need to make sure this does not result in too many unnecessary events
      track_pageview: true,
    });
  }

  public trackHomePageView = (homepageViewDateTime: string) => {
    const eventProperties = {
      [MixpanelEventProperty.HOMEPAGE_VIEW_DATE_TIME]: homepageViewDateTime,
    };
    mixpanel.track(MixpanelEvent.HOMEPAGE_VIEW, eventProperties);
  };

  public trackLinks = (query: string) => {
    mixpanel.track_links(query, MixpanelEvent.LINK_CLICK);
  };

  public trackCompleteSignUp = ({
    userId,
    loginMethod,
    username,
    displayName,
    signUpDateTime,
  }: {
    userId: string;
    loginMethod: string;
    username: string;
    displayName: string;
    signUpDateTime: string;
  }) => {
    const eventProperties = {
      [MixpanelEventProperty.LOG_IN_METHOD]: loginMethod,
    };
    const userProfile = {
      [MixpanelUserProfile.USER_ID]: userId,
      [MixpanelUserProfile.USERNAME]: username,
      [MixpanelUserProfile.NAME]: username,
      [MixpanelUserProfile.DISPLAY_NAME]: displayName,
      [MixpanelUserProfile.IS_ARTIST]: 0,
      [MixpanelUserProfile.IS_SELLING]: 0,
      [MixpanelUserProfile.SIGN_UP_DATE_TIME]: signUpDateTime,
    };
    mixpanel.track(MixpanelEvent.COMPLETE_SIGN_UP, eventProperties);
    mixpanel.identify(userId);
    mixpanel.people.set(userProfile);
  };

  public trackLogIn = ({ userId }: { userId: string }) => {
    mixpanel.identify(userId);
    mixpanel.people.set({ [MixpanelUserProfile.LAST_LOG_IN_DATE_TIME]: new Date().toISOString() });
    mixpanel.track(MixpanelEvent.LOG_IN);
  };

  public trackCreateJob = ({ job }: { job: Job }) => {
    const eventProperties = {
      [MixpanelEventProperty.JOB_ID]: job.id,
      [MixpanelEventProperty.BUYER_ID]: job.buyer_id,
      [MixpanelEventProperty.SELLER_ID]: job.seller_id,
      [MixpanelEventProperty.MIN_BUDGET]: Number(job.min_budget),
      [MixpanelEventProperty.MAX_BUDGET]: Number(job.max_budget),
      [MixpanelEventProperty.CURRENCY_CODE]: job.currency_code,
      [MixpanelEventProperty.STATUS]: job.status,
      [MixpanelEventProperty.IS_EXCLUSIVE]: job.is_exclusive,
      [MixpanelEventProperty.PUBLICATION_DATE_TIME]: job.publication_date_time,
      [MixpanelEventProperty.DEADLINE_DATE_TIME]: job.deadline_date_time,
      [MixpanelEventProperty.EXPIRATION_DATE_TIME]: job.expiration_date_time,
      [MixpanelEventProperty.IS_COMMERCIAL_ARTWORK]: job.is_comercial_artwork,
      [MixpanelEventProperty.IS_PRIVATE_ARTWORKL]: job.is_private_artwork,
    };
    mixpanel.track(MixpanelEvent.CREATE_JOB, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.BUYER_JOB_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_BUYER_JOB_PUBLICATION_DATE_TIME]: job.publication_date_time,
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_BUYER_JOB_PUBLICATION_DATE_TIME]: job.publication_date_time,
    });
  };

  public trackCreateContract = ({ contract }: { contract: Contract }) => {
    const eventProperties = {
      [MixpanelCreateContractEventProperty.CONTRACT_ID]: contract.id,
      [MixpanelCreateContractEventProperty.STATUS]: contract.status,
      [MixpanelCreateContractEventProperty.PUBLICATION_DATE_TIME]: contract.publication_date_time,
      [MixpanelCreateContractEventProperty.IS_COMMERCIAL_ARTWORK]: contract.is_comercial_artwork,
      [MixpanelCreateContractEventProperty.IS_PRIVATE_ARTWORK]: contract.is_private_artwork,
      [MixpanelCreateContractEventProperty.AMOUNT]: Number(contract.amount),
      [MixpanelCreateContractEventProperty.CURRENCY_CODE]: contract.currency_code,
      [MixpanelCreateContractEventProperty.STATE_NAME]: contract.state_name,
      [MixpanelCreateContractEventProperty.JOB_ID]: contract.job_id,
      [MixpanelCreateContractEventProperty.PROPOSAL_ID]: contract.proposal_id,
      [MixpanelCreateContractEventProperty.BUYER_ID]: contract.buyer_id,
      [MixpanelCreateContractEventProperty.SELLER_ID]: contract.seller_id,
    };
    mixpanel.track(MixpanelEvent.CREATE_CONTRACT, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.SELLER_CONTRACT_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_SELLER_CONTRACT_PUBLICATION_DATE_TIME]:
        contract.publication_date_time,
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_SELLER_CONTRACT_PUBLICATION_DATE_TIME]:
        contract.publication_date_time,
    });
  };

  // Search
  public trackStartSearch = ({
    fromScreen,
    platform,
    isLoggedIn,
  }: {
    fromScreen: string;
    platform: string;
    isLoggedIn: boolean;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.FROM_SCREEN]: fromScreen,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
    };
    mixpanel.track(MixpanelEvent.START_SEARCH, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.START_SEARCH_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_START_SEARCH_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_START_SEARCH_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackGetSearchResult = ({
    searchKeyword,
    platform,
    isLoggedIn,
    currentTab,
    currentPage,
    filterUserType,
    filterCommissionStatus,
    filterStarRating,
    filterPriceRange,
    filterCategory,
  }: {
    searchKeyword: string;
    platform: string;
    isLoggedIn: boolean;
    currentTab: string;
    currentPage: number;
    filterUserType: string;
    filterCommissionStatus: string;
    filterStarRating: string;
    filterPriceRange: string;
    filterCategory: string;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.SEARCH_KEYWORD]: searchKeyword,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
      [MixpanelSearchEventProperty.CURRENT_TAB]: currentTab,
      [MixpanelSearchEventProperty.CURRENT_PAGE]: currentPage,
      [MixpanelSearchEventProperty.FILTER_USER_TYPE]: filterUserType,
      [MixpanelSearchEventProperty.FILTER_COMMISION_STATUS]: filterCommissionStatus,
      [MixpanelSearchEventProperty.FILTER_STAR_RATING]: filterStarRating,
      [MixpanelSearchEventProperty.FILTER_PRICE_RANGE]: filterPriceRange,
      [MixpanelSearchEventProperty.FILTER_CATEGORY]: filterCategory,
    };
    mixpanel.track(MixpanelEvent.GET_SEARCH_RESULT, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.GET_SEARCH_RESULT_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_GET_SEARCH_RESULT_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_GET_SEARCH_RESULT_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackUseSearchSuggestion = ({
    searchKeywork,
    searchSuggestionKeyword,
    platform,
    isLoggedIn,
  }: {
    searchKeywork: string;
    searchSuggestionKeyword: string;
    platform: string;
    isLoggedIn: boolean;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.SEARCH_KEYWORD]: searchKeywork,
      [MixpanelSearchEventProperty.SEARCH_SUGGESTION_KEYWORD]: searchSuggestionKeyword,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
    };
    mixpanel.track(MixpanelEvent.USE_SEARCH_SUGGESTION, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.USE_SEARCH_SUGGESTION_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_USE_SEARCH_SUGGESTION_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_USE_SEARCH_SUGGESTION_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackFromSearchToProduct = ({
    searchKeyword,
    platform,
    isLoggedIn,
    currentTab,
    currentPage,
    filterUserType,
    filterCommissionStatus,
    filterStarRating,
    filterPriceRange,
    filterCategory,
    productId,
    categoryId,
    divId,
  }: {
    searchKeyword: string;
    platform: string;
    isLoggedIn: boolean;
    currentTab: string;
    currentPage: number;
    filterUserType: string;
    filterCommissionStatus: string;
    filterStarRating: string;
    filterPriceRange: string;
    filterCategory: string;
    productId: string;
    categoryId: string;
    divId: string;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.SEARCH_KEYWORD]: searchKeyword,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
      [MixpanelSearchEventProperty.CURRENT_TAB]: currentTab,
      [MixpanelSearchEventProperty.CURRENT_PAGE]: currentPage,
      [MixpanelSearchEventProperty.FILTER_USER_TYPE]: filterUserType,
      [MixpanelSearchEventProperty.FILTER_COMMISION_STATUS]: filterCommissionStatus,
      [MixpanelSearchEventProperty.FILTER_STAR_RATING]: filterStarRating,
      [MixpanelSearchEventProperty.FILTER_PRICE_RANGE]: filterPriceRange,
      [MixpanelSearchEventProperty.FILTER_CATEGORY]: filterCategory,
      [MixpanelSearchEventProperty.PRODUCT_ID]: productId,
      [MixpanelSearchEventProperty.CATEGORY_ID]: categoryId,
    };
    mixpanel.track(MixpanelEvent.FROM_SEARCH_TO_PRODUCT, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.FROM_SEARCH_TO_PRODUCT_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_FROM_SEARCH_TO_PRODUCT_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_FROM_SEARCH_TO_PRODUCT_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackFromSearchToUser = ({
    searchKeyword,
    platform,
    isLoggedIn,
    currentTab,
    currentPage,
    filterUserType,
    filterCommissionStatus,
    filterStarRating,
    filterPriceRange,
    filterCategory,
    userId,
    userType,
    isFromSuggestion,
    isSelling,
    divId,
  }: {
    searchKeyword: string;
    platform: string;
    isLoggedIn: boolean;
    currentTab: string;
    currentPage: number;
    filterUserType: string;
    filterCommissionStatus: string;
    filterStarRating: string;
    filterPriceRange: string;
    filterCategory: string;
    userId: string;
    userType: string;
    isFromSuggestion: boolean;
    isSelling: boolean;
    divId: string;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.SEARCH_KEYWORD]: searchKeyword,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
      [MixpanelSearchEventProperty.CURRENT_TAB]: currentTab,
      [MixpanelSearchEventProperty.CURRENT_PAGE]: currentPage,
      [MixpanelSearchEventProperty.FILTER_USER_TYPE]: filterUserType,
      [MixpanelSearchEventProperty.FILTER_COMMISION_STATUS]: filterCommissionStatus,
      [MixpanelSearchEventProperty.FILTER_STAR_RATING]: filterStarRating,
      [MixpanelSearchEventProperty.FILTER_PRICE_RANGE]: filterPriceRange,
      [MixpanelSearchEventProperty.FILTER_CATEGORY]: filterCategory,
      [MixpanelSearchEventProperty.USER_ID]: userId,
      [MixpanelSearchEventProperty.USER_TYPE]: userType,
      [MixpanelSearchEventProperty.IS_FROM_SUGGESTION]: isFromSuggestion,
      [MixpanelSearchEventProperty.IS_SELLING]: isSelling,
    };
    console.log('thanh4 divId: ', divId);
    mixpanel.track(MixpanelEvent.FROM_SEARCH_TO_USER, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.FROM_SEARCH_TO_USER_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_FROM_SEARCH_TO_USER_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_FROM_SEARCH_TO_USER_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackFromSearchToArtwork = ({
    searchKeyword,
    platform,
    isLoggedIn,
    currentTab,
    currentPage,
    filterUserType,
    filterCommissionStatus,
    filterStarRating,
    filterPriceRange,
    filterCategory,
    artworkId,
    username,
    divId,
  }: {
    searchKeyword: string;
    platform: string;
    isLoggedIn: boolean;
    currentTab: string;
    currentPage: number;
    filterUserType: string;
    filterCommissionStatus: string;
    filterStarRating: string;
    filterPriceRange: string;
    filterCategory: string;
    artworkId: string;
    username: string;
    divId: string;
  }) => {
    const eventProperties = {
      [MixpanelSearchEventProperty.SEARCH_KEYWORD]: searchKeyword,
      [MixpanelSearchEventProperty.PLATFORM]: platform,
      [MixpanelSearchEventProperty.IS_LOGGED_IN]: isLoggedIn,
      [MixpanelSearchEventProperty.CURRENT_TAB]: currentTab,
      [MixpanelSearchEventProperty.CURRENT_PAGE]: currentPage,
      [MixpanelSearchEventProperty.FILTER_USER_TYPE]: filterUserType,
      [MixpanelSearchEventProperty.FILTER_COMMISION_STATUS]: filterCommissionStatus,
      [MixpanelSearchEventProperty.FILTER_STAR_RATING]: filterStarRating,
      [MixpanelSearchEventProperty.FILTER_PRICE_RANGE]: filterPriceRange,
      [MixpanelSearchEventProperty.FILTER_CATEGORY]: filterCategory,
      [MixpanelSearchEventProperty.ARTWORK_ID]: artworkId,
      [MixpanelSearchEventProperty.USERNAME]: username,
    };
    mixpanel.track(MixpanelEvent.FROM_SEARCH_TO_ARTWORK, eventProperties);

    mixpanel.people.increment({ [MixpanelUserProfile.FROM_SEARCH_TO_ARTWORK_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_FROM_SEARCH_TO_ARTWORK_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_FROM_SEARCH_TO_ARTWORK_DATE_TIME]: new Date().toISOString(),
    });
  };

  public trackCreateCharacter = ({
    characterId,
    characterDisplayName,
    userId,
  }: {
    characterId: string;
    characterDisplayName: string;
    userId: string;
  }) => {
    const eventProperties = {
      [MixpanelCharacterEventProperty.CHARACTER_ID]: characterId,
      [MixpanelCharacterEventProperty.CHARACTER_DISPLAY_NAME]: characterDisplayName,
      [MixpanelCharacterEventProperty.USER_ID]: userId,
    };
    mixpanel.track(MixpanelEvent.CREATE_CHARACTER, eventProperties);
    mixpanel.people.increment({ [MixpanelUserProfile.CHARACTER_TOTAL_NUM]: 1 });
    mixpanel.people.set_once({
      [MixpanelUserProfile.FIRST_CHARACTER_DATE_TIME]: new Date().toISOString(),
    });
    mixpanel.people.set({
      [MixpanelUserProfile.LAST_CHARACTER_DATE_TIME]: new Date().toISOString(),
    });
  };
}
