export const MixpanelEvent = {
  COMPLETE_SIGN_UP: 'complete_sign_up',
  LOG_IN: 'log_in',
  HOMEPAGE_VIEW: 'homepage_view',
  LINK_CLICK: 'link_click',
  CREATE_JOB: 'create_job',
  CREATE_CONTRACT: 'create_contract',
  // Search events
  START_SEARCH: 'start_search',
  GET_SEARCH_RESULT: 'get_search_result',
  USE_SEARCH_SUGGESTION: 'use_search_suggestion',
  FROM_SEARCH_TO_PRODUCT: 'from_search_to_product',
  FROM_SEARCH_TO_USER: 'from_search_to_user',
  FROM_SEARCH_TO_ARTWORK: 'from_search_to_artwork',
  // Character events
  CREATE_CHARACTER: 'create_character',
};

export const MixpanelUserProfile = {
  USER_ID: 'user_id',
  USERNAME: 'username',
  NAME: '$name',
  DISPLAY_NAME: 'display_name',
  IS_ARTIST: 'is_artist',
  IS_SELLING: 'is_selling',
  SIGN_UP_DATE_TIME: 'sign_up_date_time',
  TOTAL_HOMEPAGE_VIEW_NUM: 'total_homepage_view_num',
  FIRST_HOMEPAGE_VIEW_DATE_TIME: 'first_homepage_view_date_time',
  LAST_HOMEPAGE_VIEW_DATE_TIME: 'last_homepage_view_date_time',
  LAST_LOG_IN_DATE_TIME: 'last_log_in_date_time',

  // Jobs
  FIRST_BUYER_JOB_PUBLICATION_DATE_TIME: 'first_buyer_job_publication_date_time',
  LAST_BUYER_JOB_PUBLICATION_DATE_TIME: 'last_buyer_job_publication_date_time',
  BUYER_JOB_TOTAL_NUM: 'buyer_job_total_num',
  FIRST_SELLER_JOB_PUBLICATION_DATE_TIME: 'first_seller_job_publication_date_time',
  LAST_SELLER_JOB_PUBLICATION_DATE_TIME: 'last_seller_job_publication_date_time',
  SELLER_JOB_TOTAL_NUM: 'seller_job_total_num',

  // Contracts
  FIRST_BUYER_CONTRACT_PUBLICATION_DATE_TIME: 'first_buyer_contract_publication_date_time',
  LAST_BUYER_CONTRACT_PUBLICATION_DATE_TIME: 'last_buyer_contract_publication_date_time',
  BUYER_CONTRACT_TOTAL_NUM: 'buyer_contract_total_num',
  FIRST_SELLER_CONTRACT_PUBLICATION_DATE_TIME: 'first_seller_contract_publication_date_time',
  LAST_SELLER_CONTRACT_PUBLICATION_DATE_TIME: 'last_seller_contract_publication_date_time',
  SELLER_CONTRACT_TOTAL_NUM: 'seller_contract_total_num',

  // Search
  START_SEARCH_TOTAL_NUM: 'start_search_total_num',
  FIRST_START_SEARCH_DATE_TIME: 'first_start_search_date_time',
  LAST_START_SEARCH_DATE_TIME: 'last_start_search_date_time',
  GET_SEARCH_RESULT_TOTAL_NUM: 'get_search_result_total_num',
  FIRST_GET_SEARCH_RESULT_DATE_TIME: 'first_get_search_result_date_time',
  LAST_GET_SEARCH_RESULT_DATE_TIME: 'last_get_search_result_date_time',
  USE_SEARCH_SUGGESTION_TOTAL_NUM: 'use_search_suggestion_total_num',
  FIRST_USE_SEARCH_SUGGESTION_DATE_TIME: 'first_use_search_suggestion_date_time',
  LAST_USE_SEARCH_SUGGESTION_DATE_TIME: 'last_use_search_suggestion_date_time',
  FROM_SEARCH_TO_PRODUCT_TOTAL_NUM: 'from_search_to_product_total_num',
  FIRST_FROM_SEARCH_TO_PRODUCT_DATE_TIME: 'first_from_search_to_product_date_time',
  LAST_FROM_SEARCH_TO_PRODUCT_DATE_TIME: 'last_from_search_to_product_date_time',
  FROM_SEARCH_TO_USER_TOTAL_NUM: 'from_search_to_user_total_num',
  FIRST_FROM_SEARCH_TO_USER_DATE_TIME: 'first_from_search_to_user_date_time',
  LAST_FROM_SEARCH_TO_USER_DATE_TIME: 'last_from_search_to_user_date_time',
  FROM_SEARCH_TO_ARTWORK_TOTAL_NUM: 'from_search_to_artwork_total_num',
  FIRST_FROM_SEARCH_TO_ARTWORK_DATE_TIME: 'first_from_search_to_artwork_date_time',
  LAST_FROM_SEARCH_TO_ARTWORK_DATE_TIME: 'last_from_search_to_artwork_date_time',

  // Character
  CHARACTER_TOTAL_NUM: 'character_total_num',
  FIRST_CHARACTER_DATE_TIME: 'first_character_date_time',
  LAST_CHARACTER_DATE_TIME: 'last_character_date_time',
};

export const MixpanelEventProperty = {
  LOG_IN_METHOD: 'log_in_method', // PHONE or EMAIL
  HOMEPAGE_VIEW_DATE_TIME: 'homepage_view_date_time',
  DESTINATION_PATHNAME: 'destination_pathname',

  // create_job event
  JOB_ID: 'job_id',
  BUYER_ID: 'buyer_id',
  SELLER_ID: 'seller_id',
  MIN_BUDGET: 'min_budget',
  MAX_BUDGET: 'max_budget',
  CURRENCY_CODE: 'currency_code',
  STATUS: 'status',
  IS_EXCLUSIVE: 'is_exclusive',
  PUBLICATION_DATE_TIME: 'publication_date_time',
  DEADLINE_DATE_TIME: 'deadline_date_time',
  EXPIRATION_DATE_TIME: 'expiration_date_time',
  IS_COMMERCIAL_ARTWORK: 'is_commercial_artwork',
  IS_PRIVATE_ARTWORKL: 'is_private_artwork',
};

export const MixpanelCreateContractEventProperty = {
  CONTRACT_ID: 'contract_id',
  STATUS: 'status',
  PUBLICATION_DATE_TIME: 'publication_date_time',
  IS_COMMERCIAL_ARTWORK: 'is_commercial_artwork',
  IS_PRIVATE_ARTWORK: 'is_private_artwork',
  AMOUNT: 'amount',
  CURRENCY_CODE: 'currency_code',
  STATE_NAME: 'state_name',
  JOB_ID: 'job_id',
  PROPOSAL_ID: 'proposal_id',
  BUYER_ID: 'buyer_id',
  SELLER_ID: 'seller_id',
};

export const MixpanelSearchEventProperty = {
  SEARCH_KEYWORD: 'search_keyword',
  SEARCH_SUGGESTION_KEYWORD: 'search_suggestion_keyword',
  FROM_SCREEN: 'from_screen',
  PLATFORM: 'platform', // DESKTOP_WEB or MOBILE_WEB
  IS_LOGGED_IN: 'is_logged_in',
  IS_FROM_SUGGESTION: 'is_from_suggestion',
  CURRENT_TAB: 'current_tab',
  CURRENT_PAGE: 'current_page',
  FILTER_USER_TYPE: 'filter_user_type',
  FILTER_COMMISION_STATUS: 'filter_commision_status',
  FILTER_STAR_RATING: 'filter_star_rating',
  FILTER_CATEGORY: 'filter_category',
  FILTER_PRICE_RANGE: 'filter_price_range',
  PRODUCT_ID: 'product_id',
  CATEGORY_ID: 'category_id',
  USER_ID: 'user_id',
  USERNAME: 'username',
  USER_TYPE: 'user_type', // BUYER or SELLER
  IS_SELLING: 'is_selling',
  ARTWORK_ID: 'artwork_id',
};

export const MixpanelCharacterEventProperty = {
  CHARACTER_ID: 'character_id',
  CHARACTER_DISPLAY_NAME: 'character_display_name',
  USER_ID: 'user_id',
};
