import UppyUploadPhotoButton from '@components/buttons/UppyUploadPhotoButton';
import PrimaryButton from '@components/buttons/primary/PrimaryButton';
import MultiLineInputField from '@components/input/MultiLineInputField';
import PrimaryInputField from '@components/input/PrimaryInputField';
import { LocalPhoto } from '@components/job/NewJob';
import { CharacterType } from '@src/customGraphql/customModels';
import { DreamerlyUser } from '@src/providers/AuthProvider';
import { RealArtworkService } from '@src/services/artworkService';
import { RealCharacterService } from '@src/services/characterService';
import { RealImageService } from '@src/services/imageService';
import captureException from '@src/services/loggerService';
import { customImageLoader } from '@src/utils/media';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function CreateCharacterDrawerView({
  authUser,
  onSuccess,
}: {
  authUser: DreamerlyUser;
  onSuccess?: () => void;
}) {
  const router = useRouter();

  const characterService = useMemo(() => new RealCharacterService(), []);
  const imageService = useMemo(() => new RealImageService(), []);
  const artworkService = useMemo(() => new RealArtworkService(), []);

  // Upload profile photo dashboard modal state
  const [isProfilePhotoDashboardModalOpen, setIsProfilePhotoDashboardModalOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<LocalPhoto>();

  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  // Reference photos
  const [isRefPhotosDashboardModalOpen, setIsRefPhotosDashboardModalOpen] = useState(false);
  const [refPhotos, setRefPhotos] = useState<LocalPhoto[]>([]);

  // Submit button loading state
  const [isLoading, setIsLoading] = useState(false);

  // Upload profile photo callback
  const handleProfilePhotoS3KeyCallback = (s3KeyString: string, srcUrl: string) => {
    const addedPhoto = {
      id: s3KeyString || uuidv4(),
      srcUrl: srcUrl,
      type: 'unsaved',
      ddbId: null,
    } as LocalPhoto;
    // TN-example
    setProfilePhoto(addedPhoto);
  };

  // Remove profile photo
  const handleRemoveProfilePhoto = async () => {
    if (profilePhoto) {
      const type = profilePhoto.type;
      const ddbId = profilePhoto.ddbId;
      setProfilePhoto(undefined);
      if (type === 'saved' && ddbId) {
        await imageService.delete(ddbId);
      }
    }
  };

  // Upload reference photos callback
  const handleReferencePhotosS3KeyCallback = (s3KeyString: string, srcUrl: string) => {
    const addedPhoto = {
      id: s3KeyString || uuidv4(),
      srcUrl: srcUrl,
      type: 'unsaved',
      ddbId: null,
    } as LocalPhoto;
    setRefPhotos((prevState) => [...prevState, addedPhoto]);
  };

  // Remove reference photo
  const handleRemoveReferencePhoto = async (photoToRemove: LocalPhoto) => {
    const newAddedPhotos = refPhotos.filter((photo) => photo.id !== photoToRemove.id);
    setRefPhotos(newAddedPhotos);
    if (photoToRemove.type === 'saved' && photoToRemove.ddbId) {
      await imageService.delete(photoToRemove.ddbId);
    }
  };

  // Helper function to validate input
  const validateInput = () => {
    // Validate input
    let isValid = true;

    // displayName cannot be empty
    if (displayName.trim() === '') {
      setDisplayNameError('Tên nhân vật không được để trống.');
      isValid = false;
    }

    // description cannot be empty
    if (description.trim() === '') {
      setDescriptionError('Mô tả không được để trống.');
      isValid = false;
    }
    return isValid;
  };

  const handleCreateCharacterArtwork = async (
    characterIdToUse: string,
    srcUrl: string | undefined,
  ) => {
    if (!srcUrl) return;

    // Add watermark to the image
    const watermarkText = `Artwork for ${authUser.username}`;
    const imageTitle = `${authUser.username}-${Math.floor(Math.random() * 100000)}`;
    // Generate watermark url
    const watermarkImage = await imageService
      .generateWatermark({
        imageUrl: srcUrl,
        imageName: imageTitle,
        text: watermarkText,
      })
      .catch(() => {
        // TODO: handler error
      });
    console.log('QQ: Generated watermark url', watermarkImage);
    // if (!watermarkImage || !watermarkImage.src_url) {
    //   throw new Error('Failed to generate watermark url');
    // }

    // Create Artwork corresponding to profilePhoto and create CharacterItem,
    const artworkId = uuidv4();
    const createArtworkPromise = artworkService.createFromSrcUrl({
      artworkId: artworkId,
      userId: 'NONE', // No artist yet
      buyerId: authUser.id,
      srcUrl: srcUrl,
      thumbnailUrl: srcUrl,
      title: `${authUser.username}-${Math.floor(Math.random() * 100000)}`,
      description: 'Artwork description',
      rawSrcUrl: srcUrl,
      rawThumbnailUrl: srcUrl,
    });
    const createCharacterItemPromise = characterService.createCharacterItem({
      characterId: characterIdToUse,
      artworkId: artworkId,
    });

    await Promise.all([createArtworkPromise, createCharacterItemPromise]);
  };

  const mixpanelTracking = async ({
    characterId,
    characterDisplayName,
    userId,
  }: {
    characterId: string;
    characterDisplayName: string;
    userId: string;
  }) => {
    try {
      const { MixpanelTracking } = await import('@src/utils/mixpanelService');

      MixpanelTracking.getInstance().trackCreateCharacter({
        characterId,
        characterDisplayName,
        userId,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleSubmitButton = async () => {
    try {
      setIsLoading(true);

      // Validate input
      const isValid = validateInput();

      // If invalid input, just return
      if (!isValid) {
        setIsLoading(false);
        return;
      }

      const characterIdToUse = uuidv4();

      // For profile picture: Do not create artwork & character_item, only update character
      // Create reference artworks & character items
      const createRefArtworksPromises = refPhotos.map((photo) =>
        handleCreateCharacterArtwork(characterIdToUse, photo.srcUrl),
      );

      // Create character
      const createCharacterPromise = characterService.create({
        id: characterIdToUse,
        userId: authUser.id,
        characterType: CharacterType.ORIGINAL_CHARACTER,
        displayName,
        description,
        profilePictureUrl: profilePhoto?.srcUrl,
      });

      // Mixpanel tracking
      const mixpanelTrackingPromise = mixpanelTracking({
        characterId: characterIdToUse,
        characterDisplayName: displayName,
        userId: authUser.id,
      });

      // Run all promises in parallel
      const [character] = await Promise.all([
        createCharacterPromise,
        ...createRefArtworksPromises,
        mixpanelTrackingPromise,
      ]);

      // If onSuccess callback is provided, call it
      if (onSuccess) {
        // Reset all states
        setDisplayName('');
        setDisplayNameError('');
        setDescription('');
        setDescriptionError('');
        setIsLoading(false);

        // Call onSuccess callback
        onSuccess();
      } else {
        // Otherwise, redirect to character page
        router.push(`/characters/${character.charname}`);
      }
    } catch (error) {
      captureException(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col overflow-clip h-full">
      <div className="flex flex-col overflow-auto h-full">
        <div className="flex flex-col px-4 md:px-6 pt-4 md:pt-0 pb-4">
          <h2 className="font-semibold text-xl md:text-m pb-2">Ảnh đại diện</h2>
          {!profilePhoto && (
            <UppyUploadPhotoButton
              isDashboardModalOpen={isProfilePhotoDashboardModalOpen}
              setIsDashboardModalOpen={setIsProfilePhotoDashboardModalOpen}
              handleS3Key={handleProfilePhotoS3KeyCallback}
              uppyOptions={{
                restrictions: {
                  maxFileSize: 25 * 1024 * 1024, // 25MB in bytes
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['image/*'],
                },
              }}
              imageEditorOptions={{
                quality: 0.9,
                cropperOptions: {
                  croppedCanvasOptions: {},
                  viewMode: 1,
                  initialAspectRatio: 1,
                  aspectRatio: 1,
                  modal: false,
                },
                actions: {
                  revert: false,
                  rotate: false,
                  granularRotate: false,
                  flip: false,
                  zoomIn: true,
                  zoomOut: true,
                  cropSquare: true,
                  cropWidescreen: false,
                  cropWidescreenVertical: false,
                },
              }}
              autoOpenFileEditor={true} // Cannot be dynamic
              note="Tối đa 1 ảnh, dung lượng không quá 25MB mỗi ảnh."
            >
              <button
                className="flex items-center justify-center w-28 h-28 rounded-lg
                border border-greyscale-g3 border-dashed hover:bg-greyscale-g1"
                onClick={() => setIsProfilePhotoDashboardModalOpen(true)}
              >
                <div className="flex shrink-0">
                  <NextImage
                    src="/ic-plus-primary.svg"
                    width={20}
                    height={20}
                    alt="Upload photo icon"
                  />
                </div>
                <span className="ml-1 font-semibold text-primary-p4 text-m md:text-s-tall">
                  Thêm
                </span>
              </button>
            </UppyUploadPhotoButton>
          )}
          {!!profilePhoto && (
            <div className="relative w-28 h-28">
              <button
                className="absolute -top-1.5 -right-1.5 flex items-center justify-center p-0.5
                      rounded-full bg-white border border-greyscale-g3 z-10 hover:shadow-md"
                onClick={handleRemoveProfilePhoto}
              >
                <NextImage src="/ic-close.svg" alt="Remove file" height={12} width={12} />
              </button>
              <div className="relative w-28 h-28 rounded-lg overflow-hidden border border-greyscale-g3">
                <NextImage
                  objectFit="cover"
                  src={profilePhoto.srcUrl || ''}
                  loader={customImageLoader}
                  alt="Profile photo"
                  width={112}
                  height={112}
                />
              </div>
            </div>
          )}
          <h2 className="font-semibold text-xl md:text-m pt-4 pb-2">Thông tin nhân vật</h2>
          <div className="pb-4">
            <PrimaryInputField
              type="text"
              placeholder="Tên"
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
                setDisplayNameError('');
              }}
              sizeType="sm"
              errorMessage={displayNameError}
            />
          </div>
          <MultiLineInputField
            placeholder="Mô tả: đặc điểm, hoàn cảnh, tính cách..."
            value={description}
            rows={4}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError('');
            }}
            sizeType="sm"
            errorMessage={descriptionError}
            autoFocus
          />
          <div className="w-full pt-1">
            {/* Photo preview section  */}
            {/* TODO add logic to limit the max number of photo upload */}
            {refPhotos.length > 0 && (
              <div className="pt-3 grid grid-cols-3 gap-3">
                {refPhotos.map((photo) => (
                  <div key={photo.id} className="relative">
                    <button
                      className="absolute -top-1.5 -right-1.5 flex items-center justify-center p-0.5
                      rounded-full bg-white border border-greyscale-g2 z-10 hover:shadow-md"
                      onClick={() => handleRemoveReferencePhoto(photo)}
                    >
                      <NextImage src="/ic-close.svg" alt="Remove file" height={12} width={12} />
                    </button>
                    <div className="relative w-full aspect-square rounded-lg overflow-hidden">
                      <NextImage
                        objectFit="cover"
                        src={photo.srcUrl || ''}
                        loader={customImageLoader}
                        alt="New job preview photo"
                        layout="fill"
                        sizes="112px"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="pt-1">
              <UppyUploadPhotoButton
                isDashboardModalOpen={isRefPhotosDashboardModalOpen}
                setIsDashboardModalOpen={setIsRefPhotosDashboardModalOpen}
                handleS3Key={handleReferencePhotosS3KeyCallback}
                autoOpenFileEditor={false} // Cannot be dynamic
                note="Tối đa 10 ảnh, dung lượng không quá 25MB mỗi ảnh."
              >
                <button
                  className="flex flex-row items-center py-1 pr-3"
                  onClick={() => setIsRefPhotosDashboardModalOpen(true)}
                >
                  <NextImage width={20} height={20} src="/ic-plus-primary.svg" />
                  <span className="ml-1 font-semibold text-primary-p4 text-m md:text-s-tall">
                    Thêm ảnh tham khảo
                  </span>
                </button>
              </UppyUploadPhotoButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-col px-4 py-4 md:px-6
    border-t border-greyscale-g3 bg-white"
      >
        <PrimaryButton isFullWidth sizeType="md" isLoading={isLoading} onClick={handleSubmitButton}>
          Tạo
        </PrimaryButton>
      </div>
    </div>
  );
}
