import { CreateImageInput, DeleteImageInput, Image, UpdateImageInput } from '@src/API';
import { ListData } from '@src/libs/models';
import { IImageRepo, RealImageRepo } from '@src/repositories/imageRepo';
import { IMilestoneRepo, RealMilestoneRepo } from '@src/repositories/milestoneRepo';
import captureException from './loggerService';

export interface IImageService {
  createImageForJob(
    srcUrl: string,
    thumbnailUrl: string,
    height: number,
    width: number,
    jobId: string,
  ): Promise<Image>;
  delete(imageId: string): Promise<Image>;
  create(input: CreateImageInput): Promise<Image | null>;
  update(input: UpdateImageInput): Promise<Image | null>;
  listImageByContractId(contractId: string): Promise<ListData<Image> | null>;
  listImageByMilestoneId(milestoneId: string): Promise<ListData<Image> | null>;

  generateWatermark({
    imageUrl,
    imageName,
    text,
  }: {
    imageUrl: string;
    imageName: string;
    text: string;
  }): Promise<{
    src_url: string;
    width: number;
    height: number;
  }>;
}

export class RealImageService implements IImageService {
  private imageRepo: IImageRepo;
  private milestoneRepo: IMilestoneRepo;

  public constructor() {
    this.imageRepo = new RealImageRepo();
    this.milestoneRepo = new RealMilestoneRepo();
  }

  public async createImageForJob(
    srcUrl: string,
    thumbnailUrl: string,
    height: number,
    width: number,
    jobId: string,
  ): Promise<Image> {
    const imageInput: CreateImageInput = {
      src_url: srcUrl,
      thumbnail_url: thumbnailUrl,
      height,
      width,
      job_id: jobId,
    };

    const result: Image = await this.imageRepo.create(imageInput);
    return result;
  }

  public async update(input: UpdateImageInput): Promise<Image | null> {
    try {
      const result: Image = await this.imageRepo.update(input);
      return result;
    } catch (error) {
      captureException(error);
    }
    return null;
  }
  public async create(input: CreateImageInput): Promise<Image | null> {
    try {
      const result: Image = await this.imageRepo.create(input);
      return result;
    } catch (error) {
      captureException(error);
    }
    return null;
  }

  public async delete(imageId: string): Promise<Image> {
    const imageInput: DeleteImageInput = {
      id: imageId,
    };
    return await this.imageRepo.delete(imageInput);
  }

  public async listImageByContractId(contractId: string): Promise<ListData<Image> | null> {
    try {
      let imageList: (Image | null | undefined)[] = [];
      const milestones = await this.milestoneRepo.listMilestonesByContractId(contractId);
      for (const milestone of milestones.list || []) {
        const images = await this.imageRepo.listImageByMilestoneId(milestone.id);
        imageList = imageList.concat(images.list);

        for (const revision of milestone.revisions?.items || []) {
          const revisionImages = await this.imageRepo.listImageByRevisionId(revision?.id || '');
          imageList = imageList.concat(revisionImages.list);
        }
      }

      const result: ListData<Image> = {
        list: imageList as Image[],
        nextToken: undefined,
      };
      return result as ListData<Image>;
    } catch (error) {
      captureException(error);
    }
    return null;
  }

  public async listImageByMilestoneId(milestoneId: string): Promise<ListData<Image> | null> {
    try {
      let imageList: (Image | null | undefined)[] = [];

      const milestone = await this.milestoneRepo.get(milestoneId);
      const images = await this.imageRepo.listImageByMilestoneId(milestone.id);
      imageList = imageList.concat(images.list);

      for (const revision of milestone.revisions?.items || []) {
        const revisionImages = await this.imageRepo.listImageByRevisionId(revision?.id || '');
        imageList = imageList.concat(revisionImages.list);
      }

      const result: ListData<Image> = {
        list: imageList as Image[],
        nextToken: undefined,
      };
      return result as ListData<Image>;
    } catch (error) {
      captureException(error);
    }
    return null;
  }

  generateWatermark({
    imageUrl,
    imageName,
    text,
  }: {
    imageUrl: string;
    imageName: string;
    text: string;
  }): Promise<{
    src_url: string;
    width: number;
    height: number;
  }> {
    return this.imageRepo.generateWatermark({ imageUrl, imageName, text });
  }
}
