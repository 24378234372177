import {
  CreateMilestoneInput,
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables,
  DeleteMilestoneInput,
  DeleteMilestoneMutation,
  DeleteMilestoneMutationVariables,
  GetMilestoneQuery,
  GetMilestoneQueryVariables,
  Milestone,
  MilestoneByContractIdQuery,
  MilestoneByContractIdQueryVariables,
  UpdateMilestoneInput,
  UpdateMilestoneMutation,
  UpdateMilestoneMutationVariables,
} from '@src/API';
import { customGetMilestone } from '@src/customGraphql/customMilestoneQueries';
import { customMilestoneByContractId } from '@src/customGraphql/customQueries';
import { createMilestone, deleteMilestone, updateMilestone } from '@src/graphql/mutations';
import { ListData } from '@src/libs/models';
import { API } from 'aws-amplify';

export interface IMilestoneRepo {
  create(milestone: CreateMilestoneInput): Promise<Milestone>;
  update(milestone: UpdateMilestoneInput): Promise<Milestone>;
  listMilestonesByContractId(contractId: string, nextToken?: string): Promise<ListData<Milestone>>;
  delete(id: string): Promise<Milestone>;
  deleteAllMilestoneByContractId(contractId: string): Promise<void>;
  get(milestoneId: string): Promise<Milestone>;
}

export class RealMilestoneRepo implements IMilestoneRepo {
  public async create(milestone: CreateMilestoneInput): Promise<Milestone> {
    const variables: CreateMilestoneMutationVariables = {
      input: milestone,
    };

    let res = { data: {} } as { data: CreateMilestoneMutation };
    res = (await API.graphql({
      query: createMilestone,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: CreateMilestoneMutation };

    return res.data.createMilestone as Milestone;
  }

  public async get(milestoneId: string): Promise<Milestone> {
    const variables: GetMilestoneQueryVariables = {
      id: milestoneId,
    };
    const res = (await API.graphql({
      query: customGetMilestone,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: GetMilestoneQuery };
    return res.data.getMilestone as Milestone;
  }

  public async update(milestone: UpdateMilestoneInput): Promise<Milestone> {
    const variables: UpdateMilestoneMutationVariables = {
      input: milestone,
    };

    const res = (await API.graphql({
      query: updateMilestone,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: UpdateMilestoneMutation };

    return res.data.updateMilestone as Milestone;
  }

  public async listMilestonesByContractId(
    contractId: string,
    nextToken?: string,
  ): Promise<ListData<Milestone>> {
    const limit = 500;
    const variables: MilestoneByContractIdQueryVariables = {
      limit: limit as number,
      nextToken,
      contract_id: contractId,
    };
    const res = (await API.graphql({
      query: customMilestoneByContractId,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: MilestoneByContractIdQuery };

    const items = res.data.milestoneByContractId?.items as Milestone[];
    const sortItems = items.sort((a, b) => {
      return (a.step_index || 0) - (b.step_index || 0);
    });
    const result: ListData<Milestone> = {
      list: sortItems,
      nextToken: res.data.milestoneByContractId?.nextToken as string,
    };

    return result;
  }

  public async delete(id: string): Promise<Milestone> {
    const input: DeleteMilestoneInput = {
      id,
    };
    const variables: DeleteMilestoneMutationVariables = {
      input,
    };

    const res = (await API.graphql({
      query: deleteMilestone,
      authMode: 'AWS_IAM',
      variables,
    })) as { data: DeleteMilestoneMutation };

    return res.data.deleteMilestone as Milestone;
  }

  public async deleteAllMilestoneByContractId(contractId: string): Promise<void> {
    const data = await this.listMilestonesByContractId(contractId);
    if (!data.list) {
      return;
    }
    for (const contractProductPrice of data.list) {
      await this.delete(contractProductPrice.id);
    }
    return;
  }
}
